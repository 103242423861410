
<template>
    <div>
        <div class="conteudo">
            <button @click.prevent="$emit('abrir-acao')" >Ação encerrada 1</button>
        </div>
        <div class="conteudo">
            <button @click.prevent="$emit('abrir-acao')" >Ação encerrada 2</button>
        </div>
        <div class="conteudo">
            <button @click.prevent="$emit('abrir-acao')" >Ação encerrada 3</button>
        </div>
    </div>
</template>
