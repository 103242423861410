<template>
  <div id="conteudo">
    <div v-show="pagina == 'acoes'">
      <Acoes @paginaAberta="abrirPagina" :isAtiva="paginaSelecionada.atividades.valor" />
    </div>
    <div v-show="pagina == 'atividades'">
      <Atividades @paginaAberta="abrirPagina" :isAtiva="paginaSelecionada.atividades.valor" />
    </div>
    <div v-show="pagina == 'noticias'">
      <Noticias @paginaAberta="abrirPagina" :isAtiva="paginaSelecionada.noticias.valor" />
    </div>
    <div v-show="pagina == 'declaracoes-e-certificados'">
      <CertificadosEDeclaracoes />
    </div>
  </div>
</template>

<script>
import { jwtDecode } from 'jwt-decode'
import eventBus from '@/eventBus'
import Acoes from '@/components/MenuAcoes.vue'
import Atividades from '@/components/MenuAtividades.vue'
import Noticias from '@/components/MenuNoticias.vue'
import CertificadosEDeclaracoes from '@/components/MenuCertificadosEDeclaracoes.vue'

export default {
  data() {
    return {
      userName: null,
      pagina: '',
      paginaSelecionada: {
        acoes: { nome: 'acoes', valor: false },
        atividades: { nome: 'atividades', valor: false },
        noticias: { nome: 'noticias', valor: false },
        certificadosEDeclaracoes: { nome: 'certificados-e-declaracoes', valor: false },
      }
    }
  },
  methods: {
    atualizarPagina(novaPagina) {
      if (this.pagina != novaPagina) {
        this.pagina = novaPagina;
        Object.values(this.paginaSelecionada).forEach((pagina) => {
          if (pagina.nome == novaPagina) {
            pagina.valor = true
          } else {
            pagina.valor = false
          }
        })
        eventBus.emit('paginaAberta', false)
      }
    },

    abrirPagina(isSelecionada) {
      eventBus.emit('paginaAberta', isSelecionada)
    }
  },

  created() {
    eventBus.on('changeComponent', this.atualizarPagina);

    const token = window.localStorage.getItem('auth_token')
    if (token) {
      try {
        const decodedToken = jwtDecode(token)
        this.userName = decodedToken.sub
      } catch (error) {
        console.error('Erro ao decodificar o token:', error)
      }
    }

    sessionStorage.setItem('paginaHome', '')
  },
  beforeUnmount() {
    eventBus.off('changeComponent', this.atualizarPagina);
  },
  components: {
    Acoes,
    Atividades,
    Noticias,
    CertificadosEDeclaracoes
  }
}
</script>

<style scoped>
#conteudo {
  display: flex;
  flex-direction: column;
  width: auto;
  padding: 4%;
  padding-top: 2%;
}
</style>
<style>
.conteudo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgb(72, 42, 137);
  margin-bottom: 2%;
  border-radius: 2rem;
  box-shadow: 0 4px 2px rgba(72, 42, 137, 0.45);
}

.conteudo p {
  margin: 0 2% 0 1%;
  color: #ffffff;
}

.conteudo button {
  border: none;
  height: 100%;
  width: 100%;
  border-radius: 1.95rem;
  text-align: left;
  display: block;
  font-weight: 500;
  color: black;
  padding: 2%;
  font-size: 1.95rem;
  background-color: #e5dbfa;
}

.conteudo span {
  display: flex;
  flex-direction: column;
}

.info {
  color: rgb(0, 0, 0, 0.5);
  font-size: 15px;
  margin-left: 10px;
}

#botao-voltar button {
  background-color: #f0f0f0;
  border: none;
  font-size: 1.2rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 1rem;
  border-radius: 1rem;
}
</style>