<template>
  <div class="container">
    <div id="radios">
      <input type="radio" class="btn-check" name="options-outlined" id="option1" autocomplete="off"
        @change="tipoCadastro = 'unitario'">
      <label class="btn btn-secondary" for="option1">Cadastro unitário</label>
      <input type="radio" class="btn-check" name="options-outlined" id="option2" autocomplete="off"
        @change="tipoCadastro = 'multiplo'">
      <label class="btn btn-secondary" for="option2">Cadastro multiplo</label>
    </div>
    <div v-show="tipoCadastro == 'unitario'" class="row justify-content-center">
      <div class="col-md-6">
        <h2>Cadastro(Aluno)</h2>
        <form @submit.prevent="submitForm">
          <div class="form-group">
            <label for="form-select">Unidade:</label>
            <select class="form-select" aria-label="Default select example">
              <option selected>Selecione a unidade</option>
            </select>
          </div>
          <div class="form-group">
            <label for="form-select">Curso:</label>
            <select class="form-select" aria-label="Default select example">
              <option selected>Selecione o curso</option>
            </select>
          </div>
          <div class="form-group">
            <label for="matricula">Matrícula:</label>
            <input type="text" class="form-control" id="matricula" v-model="formAluno.matricula">
          </div>
          <div class="form-group">
            <label for="name">Nome completo:</label>
            <input type="text" class="form-control" id="name" v-model="formUsuario.nome">
          </div>
          <div class="form-group">
            <label for="email">Email:</label>
            <input type="email" class="form-control" id="email" v-model="formUsuario.email">
          </div>
          <div>
            <label for="cpf">CPF:</label>
            <input type="text" class="form-control" id="cpf" v-model="formUsuario.cpf" v-maska="'###.###.###-##'"
              :disabled="formUsuario.estrangeiro">
          </div>
          <div id="radio-estrangeiro">
            <div>
              <input class="form-check-input" type="radio" name="brasileiro" id="brasileiro"
                v-model="formUsuario.estrangeiro" :value="false">
              <label class="form-check-label" for="brasileiro">
                Brasileiro
              </label>
            </div>
            <div>
              <input class="form-check-input" type="radio" name="estrangeiro" id="estrangeiro"
                v-model="formUsuario.estrangeiro" :value="true" @click="formUsuario.cpf = ''">
              <label class="form-check-label" for="estrangeiro">
                Estrangeiro
              </label>
            </div>
          </div>
          <div class="form-group">
            <label for="data-nascimento">Data de Nascimento:</label>
            <input type="text" class="form-control" id="data-nascimento" v-model="formUsuario.dataNascimento"
              v-maska="'##/##/####'">
          </div>
          <button type="submit" class="btn btn-primary" style="margin-top: 2%;">Cadastrar</button>
        </form>
      </div>
    </div>
    <div v-show="tipoCadastro == 'multiplo'" class="row" id="input-file">
      <div class="col-md-6">
        <h2>Cadastro(Aluno)</h2>
        <div class="mb-3">
          <label for="formFile" class="form-label">Escolha o arquivo de cadastro:</label>
          <input class="form-control" type="file" id="formFile">
        </div>
        <button type="submit" class="btn btn-primary" style="margin: 2% 0 0 85%;">Cadastrar</button>
      </div>
    </div>
  </div>
</template>

<script>
import { vMaska } from 'maska/vue';
export default {
  data() {
    return {
      formUsuario: {
        nome: '',
        email: '',
        cpf: '',
        //dataNascimento: '',
        estrangeiro: false,
        regraId: ''
      },
      formAluno: {
        matricula: '',
        isAtivo: true,
        idUsuario: ''
      },
      tipoCadastro: ''
    };
  },
  methods: {
    async submitForm() {
      if (this.tipoCadastro == 'unitario' && this.formUsuario.cpf.length == 14) {
        const dataUsuarioJson = JSON.stringify(this.formUsuario);
        const dataAlunoJson = JSON.stringify(this.formAluno);

        //cadstrando Usuário
        try {
          await this.$api()
            .post('/usuarios', dataUsuarioJson, {
              'content-type': 'aplication/json'
            })
            .then(() => {
              console.log("usuário cadastrado com sucesso!");
            })
        } catch (error) {
          console.log("ocorreu um erro ao fazer o cadastro", error);
        }

        //coletando id de usuario pelo Email
        try {
          const response = await this.$api().get('/usuarios', {
            params: this.formUsuario.email
          }).then(() => {
            this.tipoCadastrohis.formAluno.idUsuario = response.data.id
          })
        } catch (error) {
          console.log("ocorreu um erro no get ususario", error);
        }
        //Cadastrando Aluno
        try {
          await this.$api()
            .post('/alunos', dataAlunoJson, {
              'content-type': 'aplication/json'
            })
        } catch (error) {
          console.log("Ocorreu um erro no cadastro do aluno", error);
        }
      } else {
        alert("Por favor digite um CPF válido");
      }
    }
  },
  directives: {
    maska: vMaska
  }
}
</script>

<style scoped>
.container {
  background-color: rgb(233, 233, 233);
  width: 100%;
  border-radius: 1rem;
  padding: 1% 0;
}

#radios {
  display: flex;
  justify-content: space-evenly;
  width: 50%;
  margin: 0 25%;
  border-collapse: collapse;
}

#radios input[type="radio"]:checked+label {
  background-color: #ffffffff;
  color: #6c757dff;
  border-bottom-color: white;
  border-radius: 0.5rem 0.5rem 0 0;
  z-index: 1;
  height: 101%;
}

.row {
  background-color: #ffffffff;
  margin: -1px 20% 0;
  overflow: hidden;
  padding: 1%;
  border-radius: 1rem;
  border: 1px solid #6c757dff;
}

.col-md-6 {
  width: 100%;
}
</style>