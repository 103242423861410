import { createRouter, createWebHistory } from 'vue-router'
import DefaultLayout from '@/layouts/DefaultLayout.vue'
import LoginLayout from '@/layouts/LoginLayout.vue'
import HomeView from '@/views/HomeView.vue'
import CadastroView from '@/views/CadastroView.vue'
import ListaUsuariosView from '@/views/ListaUsuariosView'
import CompletarCadastro from '@/views/CompletarCadastro.vue'

import { jwtDecode } from 'jwt-decode'

const routes = [
    {
        path: '/',
        component: DefaultLayout,
        children: [
            {
                path: '/',
                component: HomeView
            },
            {
                path: '/cadastro',
                component: CadastroView
            },
            {
                path: '/lista-usuarios',
                component: ListaUsuariosView
            },
            {
                path: '/completar-cadastro',
                component: CompletarCadastro
            }
        ]
    },
    {
        name: 'login',
        path: '/login',
        component: LoginLayout
    },
    {
        path: '/:catchAll(.*)',
        redirect: '/'
    }

]

const router = createRouter({
  history: createWebHistory(),
  routes
})

const withoutAuthorization = [
    "login"
]

function validToken(token) {
    if(token == null){
        return false;
    }else{
        let decodedToken = jwtDecode(token);
        let dateNow = Date.now() / 1000;
        if( (decodedToken.exp > dateNow) && decodedToken.iss == "br.ufc.prex.gexapi"){
            return true;
        }
        return false;
    }
}


router.beforeEach((to, from, next) => {
    let token = window.localStorage.getItem("auth_token")

    if (to.name === 'login' && validToken(token)) {
        next({ path: '/' })
    } 
    else if (withoutAuthorization.includes(to.name) || validToken(token)) {
        next()
    } 
    else {
        next({ name: "login" })
    }
})

export default router
