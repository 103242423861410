<template>
  <footer class="footer">
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <p class="text-muted">© 2024 Seu Site. Todos os direitos reservados.</p>
        </div>
        <div class="col-md-6 text-md-right">
          <ul class="list-inline">
            <li class="list-inline-item"><a href="#">Sobre</a></li>
            <li class="list-inline-item"><a href="#">Contato</a></li>
            <li class="list-inline-item"><a href="#">Política de Privacidade</a></li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  // Lógica do componente, se necessário
}
</script>

<style scoped>
.footer {
  background-color: #f8f9fa;
  padding: 20px;
  position: relative;
  bottom: 0;
  width: 100%;
  text-align: center;
  height: 5vh;
}

.footer .list-inline-item {
  margin-right: 10px;
}

.footer .list-inline-item:last-child {
  margin-right: 0;
}

.footer a {
  color: #6c757d;
  text-decoration: none;
}

.footer a:hover {
  color: #007bff;
}
</style>
