<template>
    <div class="container">
        <div id="radios">
            <input type="radio" class="btn-check" name="options-outlined" id="option1" autocomplete="off"
                @change="tipoCadastro = 'unitario'">
            <label class="btn btn-secondary" for="option1">Cadastro unitário</label>
            <input type="radio" class="btn-check" name="options-outlined" id="option2" autocomplete="off"
                @change="tipoCadastro = 'multiplo'">
            <label class="btn btn-secondary" for="option2">Cadastro multiplo</label>
        </div>
        <div v-show="tipoCadastro == 'unitario'" class="row justify-content-center">
            <div class="col-md-6">
                <h2>Cadastro(Externo)</h2>
                <form @submit.prevent="submitForm">
                    <div class="form-group">
                        <label for="name">Nome completo:</label>
                        <input type="text" class="form-control" id="name" v-model="formData.nome">
                    </div>
                    <div>
                        <label for="email">Email:</label>
                        <input type="email" class="form-control" id="email" v-model="formData.email">
                    </div>
                    <div>
                        <label for="cpf">CPF:</label>
                        <input type="text" class="form-control" id="cpf" v-model="formData.cpf"
                            v-maska="'###.###.###-##'" :disabled="formData.estrangeiro">
                    </div>
                    <!--<div class="form-group">
                        <label for="data-nascimento">Data de Nascimento:</label>
                        <input type="text" class="form-control" id="data-nascimento" v-model="formData.dataNascimento">
                    </div>-->
                    <div id="radio-estrangeiro">
                        <div>
                            <input class="form-check-input" type="radio" name="brasileiro" id="brasileiro"
                                v-model="formData.estrangeiro" :value="false">
                            <label class="form-check-label" for="brasileiro">
                                Brasileiro
                            </label>
                        </div>
                        <div>
                            <input class="form-check-input" type="radio" name="estrangeiro" id="estrangeiro"
                                v-model="formData.estrangeiro" :value="true" @click="formData.cpf = ''">
                            <label class="form-check-label" for="estrangeiro">
                                Estrangeiro
                            </label>
                        </div>
                    </div>
                    <button type="submit" class="btn btn-primary" style="margin-top: 2%;">Cadastrar</button>
                </form>
            </div>
        </div>
        <div v-show="tipoCadastro == 'multiplo'" class="row" id="input-file">
            <div class="col-md-6">
                <h2>Cadastro(Externo)</h2>
                <div class="mb-3">
                    <label for="formFile" class="form-label">Escolha o arquivo de cadastro:</label>
                    <input class="form-control" type="file" id="formFile">
                </div>
                <button type="submit" class="btn btn-primary" style="margin: 2% 0 0 85%;">Cadastrar</button>
            </div>
        </div>
    </div>
</template>

<script>
import { vMaska } from 'maska/vue'
export default {
    data() {
        return {
            formData: {
                nome: '',
                cpf: '',
                email: '',
                estrangeiro: false,
                //dataNascimento: '',
                senha: '',
                regraID: '',

            },
            tipoCadastro: ''
        };
    },
    methods: {
        async submitForm() {
            if (this.tipoCadastro == 'unitario') {
                const dataJson = JSON.stringify(this.formData)
                try {
                    await this.$api()
                        .post('/usuarios', dataJson, {
                            'content-type': 'aplication/json'
                        })
                        .then(() => {
                            console.log("usuário cadastrado com sucesso!");
                        })
                } catch (error) {
                    console.log("ocorreru um erro", error);
                }
            }
        }
    },
    directives: {
        maska: vMaska
    }
}
</script>

<style scoped>
.container {
    background-color: rgb(233, 233, 233);
    width: 100%;
    border-radius: 1rem;
    padding: 1% 0;
}

#radios {
    display: flex;
    justify-content: space-evenly;
    width: 50%;
    margin: 0 25%;
    border-collapse: collapse;
}

#radios input[type="radio"]:checked+label {
    background-color: #ffffffff;
    color: #6c757dff;
    border-bottom-color: white;
    border-radius: 0.5rem 0.5rem 0 0;
    z-index: 1;
    height: 101%;
}

.row {
    background-color: #ffffffff;
    margin: -1px 20% 0;
    overflow: hidden;
    padding: 1%;
    border-radius: 1rem;
    border: 1px solid #6c757dff;
}

.col-md-6 {
    width: 100%;
}
</style>