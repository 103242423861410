<template>
    <div>
        <div id="botao-voltar">
            <button @click="$emit('fechar-noticia')">
                < voltar</button>
        </div>
        <div id="informacoes">
            <h2>{{ dadosNoticia.titulo }}</h2>
            <p>Data publicação: {{ dadosNoticia.dataPublicacao }}</p>
            <p v-if="dadosNoticia.editada">{{ dadosNoticia.dataEdicao }}</p>
            <p>Autor:{{ dadosNoticia.autor }}</p>
        </div>
        <div id="conteudo">
            <div class="imagem" v-if="dadosNoticia.urlImagem != ''" >
                <img :src="dadosNoticia.urlImagem">
                <p style="color: grey; margin:0; padding: 0;">(Descrição imagem)</p>
            </div>
            <p>{{ dadosNoticia.conteudo }}</p>
        </div>
        <div id="links">
            Compartilhe:
            <button type="button" class="btn btn-primary">Instagram</button>
            <button type="button" class="btn btn-primary">X(Twitter)</button>
        </div>
    </div>
</template>
<script setup>
import { watchEffect } from 'vue';

const emit = defineEmits(["titulo", "fechar-noticia"])

const dadosNoticia = {
    titulo: 'Titulo da Notícia',
    dataPublicacao: '',
    autor: '',
    conteudo: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus orci eros, ornare a condimentum
                ullamcorper, pharetra in libero. Proin malesuada, ex eget sagittis pulvinar, velit risus auctor sapien,
                sed laoreet magna purus vel risus. Phasellus maximus, nisl dictum pretium cursus, quam augue suscipit
                nibh, non ullamcorper odio lectus vitae dui. Phasellus vitae vehicula neque. Ut convallis ante hendrerit
                pharetra dignissim. Ut tincidunt ullamcorper dui nec scelerisque. In pretium dui urna, ac pulvinar est
                tempor ut. Praesent molestie mi vel turpis pharetra pellentesque. Aenean lorem est, consequat eget ante
                eu, tempus porta orci. Etiam nulla nisl, lacinia ut vestibulum vitae, molestie ac leo. Sed massa massa,
                convallis et velit nec, pellentesque pellentesque erat.

                Vivamus condimentum neque fermentum nisi elementum, quis efficitur lorem tempor. Donec tristique neque
                ac ligula tincidunt, sed commodo libero faucibus. Proin facilisis tristique pretium. Nam scelerisque
                orci in interdum congue. In nisi risus, commodo elementum neque nec, sollicitudin dignissim metus.
                Quisque ut lorem quis orci ornare pharetra eu sit amet est. Vivamus faucibus orci elit, quis varius
                neque vehicula sed.

                Nullam ipsum nisi, egestas at interdum in, viverra sit amet erat. Duis egestas, dui et mollis bibendum,
                justo eros maximus erat, ac sagittis metus neque in lectus. Suspendisse ac nibh a arcu finibus cursus ac
                et neque. Nulla quis lobortis metus, at malesuada metus. Cras eu diam quis felis ultrices pulvinar id
                sed leo. Vestibulum vulputate urna sit amet risus sodales, imperdiet feugiat libero imperdiet. Mauris mi
                turpis, sodales vel quam at, lacinia porta quam. Integer et ornare massa, sit amet feugiat tellus.

                Nunc vel mi risus. Vestibulum tincidunt erat a lorem bibendum blandit. Suspendisse potenti. Praesent nec
                sem dolor. Donec quis orci posuere, suscipit quam nec, tempor ante. Class aptent taciti sociosqu ad
                litora torquent per conubia nostra, per inceptos himenaeos. Class aptent taciti sociosqu ad litora
                torquent per conubia nostra, per inceptos himenaeos.

                Curabitur bibendum risus pulvinar leo interdum aliquet. Suspendisse quis arcu auctor, posuere est vel,
                ultrices lectus. Quisque ac orci blandit, commodo lorem et, sagittis nunc. Nam euismod tincidunt sapien
                eu auctor. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent ultricies metus eget mauris
                cursus pellentesque. Maecenas quis elit imperdiet mauris venenatis laoreet ac sit amet turpis. Sed
                ligula nibh, laoreet in placerat eget, tincidunt ac nunc. Nullam malesuada lobortis molestie. Maecenas
                posuere volutpat turpis, quis feugiat nulla aliquet sit amet. Aliquam nulla ex, posuere gravida
                vulputate in, condimentum eu nisi. Aenean tempus sagittis velit nec imperdiet.`,
    editada: false,
    dataEdicao: '',
    urlImagem: 'https://burst.shopifycdn.com/photos/working-from-home.jpg?width=1000&format=pjpg&exif=0&iptc=0'
}

const props = defineProps({
    isAtiva: Boolean
})

watchEffect(() => {
    if (props.isAtiva) {
        sessionStorage.setItem('paginaHome', dadosNoticia.titulo);
        emit('titulo')
    }
})
</script>
<style scoped>
.imagem {
    float: right;
    overflow: hidden;
    width: 45%;
    margin: 0 0 2% 2%;
    padding-top: 0.5%;
}

.imagem img{    
    border-radius: 2rem;
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
}

#informacoes {
    margin: 5% 5% 2% 0;
}

#informacoes p {
    margin-bottom: 0;
}

#conteudo {
    border-top: black solid 2px;
    padding-top: 2%;
    text-align: justify;
}

#links {
    display: flex;
    align-items: center;
    margin-bottom: 1%;
    padding: 1%;
    border: solid black 1px;
    border-radius: 1rem;
}

#links button {
    margin: 0 0.5%;
}
</style>