<template>
    <div id="list">
        <h2>Usuários cadastrados no sistema:</h2>
        <div id="search" v-show="!mostrar">
            <div>
                <button type="button" class="btn btn-primary" @click="mostrar = !mostrar" style="background-color: rgb(108, 117, 125); border:none">Filtrar busca</button>
            </div>
            <form class="form-inline">
                <input class="form-control mr-sm-2" type="search" placeholder="Pesquisar" aria-label="Search">
                <button class="btn btn-primary" type="submit" @submit.prevent style="background-color: rgb(108, 117, 125); border: none;"><img src="@/assets/lupa.png"></button>
            </form>
        </div>
        <form id="filters-form" v-show="mostrar">
            <fieldset>
                <legend>
                    Filtros:
                    <button type="button" class="btn btn-primary" @click="mostrar = !mostrar" style="background-color: rgb(108, 117, 125); border:none; float: right">voltar</button>
                </legend>
                <div id="filters">
                    <div id="options">
                        <div class="dropdown">
                            <button class="btn btn-secondary btn-sm dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                            tipo de usuário
                            </button>
                            <div class="selected-item">{{filtroSelecionado.valueOf()}}</div>
                            <ul class="dropdown-menu">
                                <li><button type="button" @click="filtroSelecionado = 'Todos' ">Todos</button></li>
                                <li><button type="button" @click="filtroSelecionado = 'Externos' ">Externos</button></li>
                                <li><button type="button" @click="filtroSelecionado = 'Alunos' ">Alunos</button></li>
                                <li><button type="button" @click="filtroSelecionado = 'Servidores' ">Servidores</button></li>
                            </ul>
                        </div>
                        <div class="dropdown">
                            <button class="btn btn-secondary btn-sm dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                            Unidade
                            </button>
                            <ul class="dropdown-menu">
                                <li></li>
                            </ul>
                        </div>
                    </div>
                <button type="submit" class="btn btn-primary" @click.prevent="mostrar = !mostrar; aplicarFiltros(filtroSelecionado.valueOf())" style="background-color: #424242ff; border:none">aplicar filtros</button>
            </div>
            </fieldset>
        </form>

        <ul class="list-group">
            <li class="list-group-item" v-for="(usuario, index) in usuarios" :key="index" v-show="usuario.filtro == tipoDeUsuario">{{usuario.nome}}</li>
        </ul>
    </div>
</template>

<script setup>
import {ref, reactive} from 'vue'

const mostrar = ref(false);
var filtroSelecionado = ref("Todos")
var tipoDeUsuario = "Todos";

const usuarios = reactive([{
    filtro: "Todos",
    nome: "Usuarios cadastrados até o momento"
},{
    filtro: "Externos",
    nome: "Usuarios externos cadastrados até o momento"
},{
    filtro: "Alunos",
    nome: "Alunos cadastrados até o momento"
},{
    filtro: "Servidores",
    nome: "Servidores cadastrados até o momento"
},])

function aplicarFiltros(filtro){
    tipoDeUsuario = filtro;

    return tipoDeUsuario;
}
</script>
    
<style scoped>
    #list{
        background-color: rgb(233, 233, 233);
        margin: 1%;
        padding: 0.5%;
        border: 1px solid grey;
        border-radius: 0.5rem;
        width: auto;
    }
    #search{
        display: flex;
        justify-content: space-between;
        background-color: white;
        margin-top: 2%;
        padding: 1%;
        border: 1px solid rgb(222, 226, 230);
        border-radius: 0.5rem;
    }
    #search form{
        display: flex;
    }
    fieldset{
        background-color: white;
        margin-top: 2%;
        padding: 0 0.5%;
        border: 1px solid rgb(222, 226, 230);
        border-radius: 0.5rem;
    }
    legend{
        margin-top: 1%;
        border-bottom: 1px solid rgb(222, 226, 230);
        padding:0 1%;
        font-size: medium;
        font-family: Arial, Helvetica, sans-serif;
    }
    #filters{
        display: flex;
        justify-content: space-between;
        padding: 0 1% 1%;
    }
    #filters button{
        margin-right: 0;
    }
    #options{
        display: flex;
    }
    #options div{
        margin-right: 1%;
    }
    li button{
        text-align: left;
        width: 100%;
        border: none;
        background-color: white;
    }
    .dropdown button{
        width: 100%;
        margin-right: 0.5vw;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
    legend{
        font-size: 15px;
    }
    .selected-item{
        background-color: rgba(181, 181, 181, 0.519);
        padding-left: 5%;
        width:100%;
        color: rgb(68, 68, 68);
        border-bottom-left-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
    }
</style>