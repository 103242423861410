<template>
    <div id="botao-voltar">
        <button @click="$emit('fechar-atividade')">
            < voltar</button>
    </div>
    <div id="Atividade">
        <div id="head">
            <div id="Titulo">
                <h2>{{ dadosAtividade.titulo }}</h2>
            </div>
            <div id="datas">
                <h6>{{ dadosAtividade.dataInicio }} - {{ dadosAtividade.dataFim }}</h6>
            </div>
        </div>
        <div id="conteudo">
            <div id="infos">
                <div id="tipo">
                    <h5>Tipo: {{ dadosAtividade.tipo }}</h5>
                </div>
                <div id="modalidade">
                    <h5>Modalidade: {{ dadosAtividade.modalidade }}</h5>
                </div>
                <div id="local">
                    <h5>Local: {{ dadosAtividade.local }}</h5>
                </div>
                <div id="acao">
                    <h5>Ação Responsável: {{ dadosAtividade.acao }}</h5>
                </div>
                <div id="descricao">
                    <h5>Descrição: </h5>
                    <h6>{{ dadosAtividade.descricao }}</h6>
                </div>
            </div>
            <div id="img">
                <h3>imagem ilustrativa</h3>
                <figure>
                    <img
                        src="https://burst.shopifycdn.com/photos/working-from-home.jpg?width=1000&format=pjpg&exif=0&iptc=0">
                </figure>
            </div>
        </div>
        <div v-show="!isInscrito">
            <div class="confirmacao" v-if="dadosAtividade.inscricao == false">
                <p>A atividade é aberta para todos</p>
            </div>
            <div class="confirmacao" v-else>
                <button @click="realizarInscricao">Realizar Inscrição</button>
            </div>
        </div>
        <div class="confirmacao" v-show="isInscrito">
            Você já está inscrito nessa atividade
        </div>
        <div id="cancelamento" v-show="isInscrito">
            <button @click="cancelarInscricao">Cancelar Inscrição</button>
        </div>
    </div>
</template>

<script setup>
import { ref, watchEffect } from 'vue'

const emit = defineEmits(["titulo", "fechar-atividade"])

const dadosAtividade = {
    titulo: "Seminarios Temáticos",
    tipo: "Minicurso",//Estou usando a variavel 'tipo' para refênciar que tipo de atividade é. Ex: minucurso, oficina, palestra, etc...
    local: "Campus UFC de Russas",
    acao: "Nerds", //Ação responsável pela atividade
    descricao: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras ac venenatis neque, sit amet facilisis sapien. Mauris in elit ligula. Maecenas volutpat pretium ex et congue. Morbi ante metus, lobortis vel dui quis, bibendum volutpat risus. Quisque pulvinar massa nec lobortis interdum. Suspendisse arcu nulla, ullamcorper vitae tellus quis, varius ultrices nunc. Pellentesque fermentum semper laoreet. In sagittis metus quis blandit consequat. Proin rutrum sem sed nunc faucibus, id interdum felis blandit. Donec porta malesuada tincidunt. Quisque vel euismod nisl. Quisque tempor suscipit sem, et lobortis ligula lacinia sed. In quis blandit lacus, in sagittis sapien. Duis sit amet porta risus, id porttitor dui. Maecenas pretium augue vel sapien dictum, sit amet pharetra purus hendrerit. Curabitur tempus, risus eu pellentesque iaculis, metus purus lacinia enim, non auctor elit augue sed nibh.",
    modalidade: "Presencial",
    inscricao: true, //A atividade precisa de inscrição ou se é uma atividade livre
    dataInicio: "05/10/2024",
    dataFim: "10/10/2024"
}

var isInscrito = ref(false) //Se o usuario já está incrito na atividade ou não

function realizarInscricao() {
    isInscrito.value = true
}
function cancelarInscricao() {
    isInscrito.value = false
}

const props = defineProps({
    isAtiva: Boolean //Recebe um booleano de Atividades.view que indica se a página de Atividade está ativa na tela ou não
})

watchEffect(() => {
    if(props.isAtiva){ //Se a tela de atividade estiver ativa
        sessionStorage.setItem('paginaHome', dadosAtividade.titulo); //Define o titulo da página ativa
        emit('titulo') //envia o titulo para Atividades.vue
    }
})
</script>
<style scoped>
#Atividade {
    padding-top: 5%;
}

#head {
    border-bottom: 2px black solid;
    padding-bottom: 1%;
}

#conteudo {
    padding-top: 4%;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

#infos {
    width: 45%;
    padding-right: 5%;
    text-align: justify;
    border-right: dotted black 2px;
}

#infos div {
    margin-bottom: 5%;
}

#img {
    position: relative;
    overflow: hidden;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 45vh;
    width: 50%;
    background-color: #e9e9e9;
    border-radius: 2rem;
}

#img h3{
    position: absolute;
}

#img img{
    max-height: 100%;
    max-width: 100%;
    object-fit: cover;
}

#head {
    text-align: center;
}

#acao {
    padding-bottom: 5%;
    border-bottom: black solid 2px;
}

#descricao h5{
    text-align: center;
}

.confirmacao {
    margin-bottom: 1%;
    display: flex;
    margin-top: 2%;
    height: 10vh;
    width: 100%;
    background-color: #e9e9e9;
    border: none;
    border-radius: 1.25rem;
    justify-content: center;
    align-items: center;
}

.confirmacao button {
    height: 100%;
    width: 100%;
    background-color: #e9e9e9;
    border: none;
    border-radius: 1.25rem;
}

.confirmacao button:hover {
    background-color: #d0d0d0;
    transition: 0.5s;
}

#cancelamento button {
    margin-bottom: 1%;
    height: 100%;
    width: 100%;
    background-color: red;
    color: white;
    border: none;
    border-radius: 1.25rem;
}
</style>