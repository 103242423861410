<template>
  <div>
    <form id="escolha-cadastro" @submit.prevent>
      <select required class="form-select form-select-lg mb-3" aria-label=".form-select-lg example" v-model="escolha">
        <option value="" disabled selected hidden>Clique para escolher o cadastro</option>
        <option value="Aluno">Cadastrar aluno</option>
        <option value="Servidor">Cadastrar servidor</option>
        <option value="Externo">Cadastrar externo</option>
      </select>
    </form>
    <div v-if="escolha == 'Aluno'">
      <CadastroAluno />
    </div>
    <div v-if="escolha == 'Servidor'">
      <CadastroServidor />
    </div>
    <div v-if="escolha == 'Externo'">
      <CadastroExterno />
    </div>
  </div>
</template>

<script setup>
import CadastroAluno from "@/components/CadastroAluno.vue"
import CadastroServidor from "@/components/CadastroServidor.vue"
import CadastroExterno from "@/components/CadastroExterno.vue"
import { ref } from 'vue'

var escolha = ref('')
</script>

<style scoped>
.container {
  background-color: rgb(233, 233, 233);
  width: 100%;
  border-radius: 1rem;
  padding: 1% 0;
}

#escolha-cadastro {
  padding: 1%;
  display: flex;
  align-items: stretch;
}

#escolha-cadastro select {

  background-color: rgb(240, 240, 240);
}

.row {
  background-color: #ffffffff;
  margin: -1px 20% 0;
  width: 60%;
  padding: 1%;
  border-radius: 1rem;
  border: 1px solid #6c757dff;
}

.col-md-6 {
  width: 100%;
}
</style>
<style>
#radio-estrangeiro {
  display: flex;
  justify-content: space-evenly;
  width: 25%;
  background-color: #e9ecefff;
  border: solid 1px #dee2e6ff;
  border-radius: 0 0 0.5rem 0.5rem;
}

#cpf{
  border-bottom-left-radius: 0;
}
</style>