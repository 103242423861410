<template>
  <div id="layout">
    <MenuSuperiorComponent />
    <div id="body">
      <MenuLateralComponent />
      <div id="content">
        <router-view />
      </div>
    </div>

  </div>
</template>

<script>
import MenuSuperiorComponent from '../components/MenuSuperiorComponent.vue';
import MenuLateralComponent from '../components/MenuLateralComponent.vue';
import Footer from '../components/FooterComponent.vue';

export default {
  components: {
    MenuSuperiorComponent,
    MenuLateralComponent,
    Footer
  },
  methods: {
    changeComponent(componentName) {
      this.$emit('changeComponent', componentName);
    }
  }
};
</script>

<style scoped>

#layout {
  display: flex;
  flex-direction: column;
  height: 100vh; 
}

MenuSuperiorComponent {
  height: 10vh;
  background-color: #007bff;
}

footer {
  background-color: #f8f9fa;
  padding: 20px 0;
  height: 10vh; 
  text-align: center;
}


#body {
  display: flex;
  flex-grow: 1; 
  overflow: hidden;
}

#menu {
  width: 20%; 
  background-color: #343a40;
  padding: 0 2%;
  overflow-y: auto; 
}

#content {
  flex: 1;
  padding: 20px;
  background-color: #f8f9fa;
  overflow-y: auto;
}

</style>
