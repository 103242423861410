
<template>
    <div v-show="!noticiaSelecionada" v-for="noticia in noticias">
        <div class="conteudo">
            <button @click="abrirNoticia">
                <span>
                    <span>{{noticia.tituloNoticia}}</span>
                    <span class="info">{{noticia.autor}}</span>
                </span>
            </button>
            <p>{{ converterDatas(noticia.dataPublicacao) }}</p>
        </div>
    </div>
    <div v-show="noticiaSelecionada">
        <NoticiaView @fechar-noticia="fecharNoticia" :isAtiva="noticiaSelecionada" @titulo="abrirNoticia"/>
    </div>
</template>

<script setup>
import NoticiaView from '@/components/Noticia.vue';
import { reactive, ref, watchEffect } from 'vue';

const emit = defineEmits(["paginaAberta"])

var noticiaSelecionada = ref(false);

const props = defineProps({
    isAtiva: Boolean
})

watchEffect(() => {
    if(!props.isAtiva){
        noticiaSelecionada.value = false;
        emit('paginaAberta', false)
    }
})

function abrirNoticia(){
    noticiaSelecionada.value = true
    emit('paginaAberta', true)
}

function fecharNoticia(){
    noticiaSelecionada.value = false
    emit('paginaAberta', false)
}

function converterDatas(data) {
    data = new Date(data);

    const dia = String(data.getDate()).padStart(2, '0'); 
    const mes = String(data.getMonth() + 1).padStart(2, '0');
    const ano = data.getFullYear();

    return `${dia}/${mes}/${ano}`;
}

const noticias = reactive([
    {
        tituloNoticia: 'Notícia 1',
        dataPublicacao: '2024-02-05',
        autor: 'Ação 1'        
    },
    {
        tituloNoticia: 'Notícia 2',
        dataPublicacao: '2023-09-01',
        autor: 'Ação 2'        
    },
    {
        tituloNoticia: 'Notícia 3',
        dataPublicacao: '2024-10-04',
        autor: 'Ação 4'        
    },
    {
        tituloNoticia: 'Notícia 4',
        dataPublicacao: '2023-06-27',
        autor: 'Ação 6'        
    },
    {
        tituloNoticia: 'Notícia 5',
        dataPublicacao: '2024-02-09',
        autor: 'Ação 2'        
    },
    {
        tituloNoticia: 'Notícia 6',
        dataPublicacao: '2024-12-29',
        autor: 'Ação 2'        
    },
    
])
</script>
