<template>
  <div id="conteudo-acao">
    <div id="botao-voltar">
      <button @click="$emit('fechar-acao')">
        < voltar
      </button>
    </div>

    <div id="titulo">
      <h2>{{ dadosAcao.titulo }}</h2>
      <h4>Coordenador: {{ dadosAcao.coordenador }}</h4>
    </div>

    <div id="menus">
      <div 
        class="item" 
        :class="{ 'opcao-selecionada': pagina === 'atividades' }">
        <button @click.prevent="pagina = 'atividades'">Atividades</button>
      </div>
      <div 
        class="item" 
        :class="{ 'opcao-selecionada': pagina === 'noticias' }">
        <button @click.prevent="pagina = 'noticias'">Notícias</button>
      </div>
      <div 
        class="item" 
        :class="{ 'opcao-selecionada': pagina === 'informacoes' }">
        <button @click.prevent="pagina = 'informacoes'">Informações</button>
      </div>
      <div 
        class="item" 
        :class="{ 'opcao-selecionada': pagina === 'declaracoes' }">
        <button @click.prevent="pagina = 'declaracoes'">{{ analiseTipoAcao() }}</button>
      </div>
      <div 
        class="item" 
        :class="{ 'opcao-selecionada': pagina === 'cadastrar-noticia' }">
        <button @click.prevent="pagina = 'cadastrar-noticia'">Adicionar notícia</button>
      </div>
    </div>

    <FormNoticia @fechar-form-noticia="pagina = ''" v-show="pagina === 'cadastrar-noticia'" />

    <div v-if="loading" class="spinner">
      <p>Carregando...</p>
    </div>

    <div v-else-if="pagina === 'atividades'">
      <div v-for="(dadosAtividade, index) in atividades" :key="index">
        <Atividade :dadosAtividade="dadosAtividade" />
      </div>
      <div v-if="atividades.length === 0">
        <p class="sem-atividades">Nenhuma atividade encontrada.</p>
      </div>

      <div id="paginacao">
        <button @click="mudarPagina(paginaAtual - 1)" :disabled="paginaAtual === 0">Anterior</button>
        <button @click="mudarPagina(paginaAtual + 1)">Próxima</button>
      </div>
    </div>

    <h4 v-show="pagina === 'noticias'">Notícias da ação</h4>
    <h4 v-show="pagina === 'informacoes'">Informações da ação</h4>
    <h4 v-show="pagina === 'declaracoes'">{{ analiseTipoAcao() }}</h4>

  </div>
</template>

<script setup>
import { ref, computed, watch, watchEffect, getCurrentInstance } from 'vue';
import FormNoticia from './FormNoticia.vue';
import Atividade from './AtividadeAcao.vue';

const { proxy } = getCurrentInstance();

const atividades = ref([]);
const pagina = ref('');
const paginaAtual = ref(0);
const loading = ref(false); 

const emit = defineEmits(["fechar-acao"]);
const props = defineProps({
  acao: Object,
  isAtiva: Boolean
});

const dadosAcao = computed(() => props.acao || {
  titulo: 'Título Padrão',
  coordenador: 'Coordenador Padrão',
  tipoAcao: 'Projeto'
});

watch(pagina, (newVal) => {
  if (newVal === 'atividades') {
    carregarAtividades();
  }
});

watchEffect(() => {
    if (props.isAtiva && dadosAcao.value) {
        sessionStorage.setItem('paginaHome', dadosAcao.value.titulo);
    }
});

async function carregarAtividades(page = 0, size = 10) {
  loading.value = true; // Início do carregamento
  try {
    const acao_id = dadosAcao.value.acao_id;
    const response = await proxy.$api.get(`/atividade/acao/${acao_id}`, {
      params: { page, size }
    });

    if (response.data && Array.isArray(response.data.content)) {
      atividades.value = response.data.content;
    } else {
      atividades.value = [];
    }
  } catch (error) {
    console.error('Erro ao carregar atividades:', error);
  } finally {
    loading.value = false; // Fim do carregamento
  }
}

function mudarPagina(novaPagina) {
  if (novaPagina >= 0) {
    paginaAtual.value = novaPagina;
    carregarAtividades(novaPagina);
  }
}

function analiseTipoAcao() {
  return dadosAcao.value.tipoAcao === 'Curso' ? 'Meus Certificados' : 'Minhas Declarações';
}
</script>

<style scoped>
/* Estilo do layout */
#conteudo-acao {
  display: flex;
  flex-direction: column;
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

/* Botão Voltar */
#botao-voltar button {
  background-color: #362065;
  color: white;
  padding: 12px 25px;
  border: none;
  border-radius: 5px;
  font-size: 1.2rem;
  margin-bottom: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

#botao-voltar button:hover {
  background-color: #6F319A;
}

/* Título e Coordenador */
#titulo {
  text-align: center;
  margin-bottom: 20px;
}

#titulo h2 {
  font-size: 2rem;
  color: #343a40;
}

#titulo h4 {
  font-size: 1.25rem;
  color: #6c757d;
}

/* Menu de Navegação */
#menus {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 20px;
  border-bottom: 2px solid #343a40;
}

.item {
  display: flex;
}

#menus button {
  border: none;
  padding: 12px 20px;
  font-size: 1.2rem;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  border-radius: 5px;
}

.opcao-selecionada button {
  background-color: #362065; 
  color: white;
}

#menus button:hover {
  background-color: rgba(54, 32, 101, 0.7);
  color: #ffffff;
}

/* Carregamento */
.spinner {
  text-align: center;
  padding: 20px;
  font-size: 1.2rem;
  color: #362065;
}

/* Sem atividades */
.sem-atividades {
  text-align: center;
  font-size: 1.75rem;
  color: #888;
  font-weight: 500;
}

/* Paginação */
#paginacao {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

#paginacao button {
  padding: 10px 20px;
  font-size: 1.1rem;
  background-color: #362065;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

#paginacao button:hover {
  background-color: #6F319A; 
}

#paginacao button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}
</style>
