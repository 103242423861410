<template>
    <div v-show="!atividadeSelecionada">
        <div id="suas-atividades">
            <h3>Suas atividades</h3>
            <div class="conteudo">
                <button @click="abrirAtividade">Suas Atividades</button>
            </div>
            <div class="conteudo">
                <button @click="abrirAtividade">Suas Atividades</button>
            </div>
            <div class="conteudo">
                <button @click="abrirAtividade">Suas Atividades</button>
            </div>
        </div>
        <div id="atividades-livres">
            <h3>Atividades livres</h3>
            <div class="conteudo">
                <button @click="abrirAtividade">Atividades Livres</button>
            </div>
            <div class="conteudo">
                <button @click="abrirAtividade">Atividades Livres</button>
            </div>
            <div class="conteudo">
                <button @click="abrirAtividade">Atividades Livres</button>
            </div>
        </div>
        <div id="incricoes-abertas">
            <h3>Atividades com inscrição</h3>
            <div class="conteudo">
                <button @click="abrirAtividade">Atividades com inscrição</button>
            </div>
            <div class="conteudo">
                <button @click="abrirAtividade">Atividades com inscrição</button>
            </div>
            <div class="conteudo">
                <button @click="abrirAtividade">Atividades com inscrição</button>
            </div>
        </div>
    </div>
    <div v-show="atividadeSelecionada">
        <Atividade @fechar-atividade="fecharAtividade" :isAtiva="atividadeSelecionada" @titulo="abrirAtividade"/>
    </div>
</template>
<script setup>
import {ref, watchEffect} from 'vue';
import Atividade from '@/components/Atividade.vue';

const emit = defineEmits(["paginaAberta"])

const props = defineProps({
    isAtiva: Boolean //Recebe um booleano de HomeView que defina a página como visível ou não
})

var atividadeSelecionada = ref(false);

watchEffect(() => {
    if(!props.isAtiva){ //Se a página não estiver ativa
        atividadeSelecionada.value = false;
        emit('paginaAberta', false)
    }
})

function abrirAtividade(){
    atividadeSelecionada.value = true;
    emit('paginaAberta', true)
}

function fecharAtividade(){
    atividadeSelecionada.value = false;
    emit('paginaAberta', false)
}


</script>
<style scoped></style>