<template>
    <div id="completar-cadastro">
        <h2>Completar Cadastro:</h2>
        <form id="form-cadastro" @submit.prevent="atualizar">
            <div class="mb-3">
                <label for="email" class="form-label">Email:</label>
                <input type="email" class="form-control" id="email" :placeholder="email" disabled="true">
                <div id="emailHelp" class="form-text">nunca vamos compartilhar seu email com ninguém</div>
            </div>
            <div class="mb-3">
                <label for="nome" class="form-label">Nome completo:</label>
                <input type="text" class="form-control" id="nome" :placeholder="nome" disabled="true">
            </div>
            <div class="mb-3">
                <label for="data-nascimento" class="form-label">Data de nascimento:</label>
                <input type="text" class="form-control" id="data-nascimento" :placeholder="dataNascimeto"
                    disabled="true">
            </div>
            <div class="mb-3">
                <label for="cpf" class="form-label">CPF:</label>
                <input type="text" class="form-control" id="cpf" v-maska="'###.###.###-##'" placeholder="Digite seu CPF">
            </div>
            <div class="mb-3">
                <label for="genero" class="form-label">Gênero:</label>
                <select id="genero" class="form-select" aria-label="Default select example" v-model="generoSelecionado">
                    <option value="" disabled selected hidden>selecione o Gênero no qual se identifica</option>
                    <option v-for="genero in genero" :value=genero>{{ genero }}</option>
                </select>
            </div>
            <div class="mb-3">
                <label for="heteroidentificação" class="form-label">Heteroidentificação:</label>
                <select id="heteroidentificação" class="form-select" aria-label="Default select example"
                    v-model="etniaSelecionada">
                    <option value="" disabled selected hidden>selecione a raça na qual se identifica</option>
                    <option v-for="etnia in etnia" :value="etnia">{{ etnia }}</option>
                </select>
            </div>
            <div class="mb-3">
                <label for="escolaridade" class="form-label">Escolaridade</label>
                <select id="escolaridade" class="form-select" aria-label="default select example"
                    v-model="escolaridadeSelecionada">
                    <option value="" disabled selected hidden>selecione a raça na qual se identifica</option>
                    <option v-for="escolaridade in escolaridade" :value="escolaridade">{{ escolaridade }}</option>
                </select>
            </div>
            <div class="mb-3">
                <label for="renda-familiar" class="form-label">Renda Familiar</label>
                <select id="renda-familiar" class="form-select" aria-label="default select example"
                    v-model="rendaFamiliarSelecionada">
                    <option value="" disabled selected hidden>selecione a raça na qual se identifica</option>
                    <option v-for="renda in rendaFamiliar" :value="renda">{{ renda }}</option>
                </select>
            </div>
            <button type="submit" class="btn btn-primary">Atualizar cadastro</button>
        </form>
    </div>
</template>

<script setup>
import {vMaska} from 'maska/vue'

var nome = "{nome do usuário}"
var cpf = "{CPF do usuário}"
var email = "{email do usuário}"
var dataNascimeto = "{Data de nascimento do usuário}"

var generoSelecionado;
var etniaSelecionada;
var escolaridadeSelecionada;
var rendaFamiliarSelecionada;

const genero = [
    "Homem Cisgêreno",
    "Homem Transgênero",
    "Mulher Cisgênero",
    "Mulher Transgênero",
    "Gênero Não Binario",
    "Agênero",
    "Gênero-Fluido",
    "Bigenero",
    "Poligenero",
    "Gênero Neutro",
    "Outro",
    "prefiro não responder"
]

const etnia = [
    "Pardo",
    "Branco",
    "Negro",
    "Indigena",
    "Amarelo",
    "Prefiro não responder"
]

const escolaridade = [
    "Não Alfabetizado",
    "Ensino Fundamental Incompleto",
    "Ensino Fundamental Completo",
    "Ensino Médio incompleto",
    "Ensino Médio Completo",
    "Ensino Superior Incompleto",
    "Ensino Superior Completo",
    "Prefiro não responder"
]

const rendaFamiliar = [
    "Menos de 1 salário mínimo",
    "De 1 a 3 salários mínimos",
    "De 4 a 7 salários mínimos",
    "Mais de 7 salários mínimos",
    "Prefiro não responder"
]

function atualizar() {
    console.log(generoSelecionado);
    console.log(etniaSelecionada);
    console.log(escolaridadeSelecionada);
    console.log(rendaFamiliarSelecionada);
}
</script>

<style scoped>
#completar-cadastro {
    margin: 6%;
    padding: 2%;
    background-color: #e9e9e9;
    border-radius: 0.5rem;
}

#form-cadastro {
    padding: 2%;
    background-color: white;
    border-radius: 0.5rem;
}
</style>