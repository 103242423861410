<template>
    <button class="botao-dropdown" @click="certificadosIsAtivo = !certificadosIsAtivo">
        <h3>Certificados ▽</h3>
    </button>

    <div v-show="certificadosIsAtivo">
        <div class="ordenacao-e-filtros">
            <div class="filtros">
                <h5>Filtros: {{ filtroCertificados }}</h5>
                <div class="btn-group">
                    <button class="btn btn-secondary btn-sm dropdown-toggle" type="button" data-bs-toggle="dropdown"
                        aria-expanded="false">
                        Ano
                    </button>
                    <ul class="dropdown-menu">
                        <li v-for="ano in anoCertificados"><button @click="filtroCertificados = ano">{{ ano }}</button>
                        </li>
                    </ul>
                </div>
                <div class="btn-group">
                    <button class="btn btn-secondary btn-sm dropdown-toggle" type="button" data-bs-toggle="dropdown"
                        aria-expanded="false">
                        Acao
                    </button>
                    <ul class="dropdown-menu">
                        <li v-for="acao in acaoCertificados"><button @click="filtroCertificados = acao">{{ acao }}</button></li>
                    </ul>
                </div>
                <div>
                    <button @click="filtroCertificados = ''" class="limpar-filtros">Limpar filtros</button>
                </div>
            </div>
            <div>
                <h5>Ordenações: {{ ordenacaoCertificados }}</h5>
                <div class="btn-group">
                    <button class="btn btn-secondary btn-sm dropdown-toggle" type="button" data-bs-toggle="dropdown"
                        aria-expanded="false">
                        Ordenar por
                    </button>
                    <ul class="dropdown-menu">
                        <button @click="ordenacaoCertificados = ordenacao" v-for="ordenacao in ordenacoes">{{ ordenacao }}</button>
                    </ul>
                </div>
            </div>
        </div>
        <div class="conteudo" v-for="certificado in certificadosFiltrados">
            <button>
                <span>
                    <span>{{ certificado.titulo }}</span>
                    <span class="info">{{ certificado.acao }}</span>
                </span>
                <span style="font-size: 15px;">{{ converterDatas(certificado.dataEmissao) }}</span>
            </button>
            <a class="botao-download" href="" download="">fazer download</a>
        </div>
    </div>

    <button class="botao-dropdown" @click="declaracoesIsAtivo = !declaracoesIsAtivo">
        <h3>Declarações ▽</h3>
    </button>
    <div v-show="declaracoesIsAtivo">
        <div class="ordenacao-e-filtros">
            <div class="filtros">
                <h5>Filtros: {{ filtroDeclaracoes }}</h5>
                <div class="btn-group">
                    <button class="btn btn-secondary btn-sm dropdown-toggle" type="button" data-bs-toggle="dropdown"
                        aria-expanded="false">
                        Ano
                    </button>
                    <ul class="dropdown-menu">
                        <li v-for="ano in anoDeclaracoes"><button @click="filtroDeclaracoes = ano">{{ ano }}</button>
                        </li>
                    </ul>
                </div>
                <div class="btn-group">
                    <button class="btn btn-secondary btn-sm dropdown-toggle" type="button" data-bs-toggle="dropdown"
                        aria-expanded="false">
                        Acao
                    </button>
                    <ul class="dropdown-menu">
                        <li v-for="acao in acaoDeclaracoes"><button @click="filtroDeclaracoes = acao">{{ acao }}</button></li>
                    </ul>
                </div>
                <div>
                    <button @click="filtroDeclaracoes = ''" class="limpar-filtros">Limpar filtros</button>
                </div>
            </div>
            <div>
                <h5>Ordenações: {{ ordenacaoDeclaracoes }}</h5>
                <div class="btn-group">
                    <button class="btn btn-secondary btn-sm dropdown-toggle" type="button" data-bs-toggle="dropdown"
                        aria-expanded="false">
                        Ordenar por
                    </button>
                    <ul class="dropdown-menu">
                        <button @click="ordenacaoDeclaracoes = ordenacao" v-for="ordenacao in ordenacoes">{{ ordenacao }}</button>
                    </ul>
                </div>
            </div>
        </div>
        <div class="conteudo" v-for="declaracao in declaracoesFiltradas">
            <button>
                <span>
                    <span>{{ declaracao.titulo }}</span>
                    <span class="info">{{ declaracao.acao }}</span>
                </span>
                <span style="font-size: 15px;">{{ converterDatas(declaracao.dataEmissao) }}</span>
            </button>
            <a class="botao-download" href="" download="">fazer download</a>
        </div>
    </div>
</template>
<script setup>
import { computed, onMounted, reactive, ref } from 'vue'

var certificadosIsAtivo = ref(true);
var declaracoesIsAtivo = ref(true);

var filtroCertificados = ref('');
var filtroDeclaracoes = ref('');

var ordenacaoCertificados = ref('Data(Mais recente primeiro)');
var ordenacaoDeclaracoes = ref('Data(Mais recente primeiro)');

const anoCertificados = reactive([]);
const acaoCertificados = reactive([]);
const anoDeclaracoes = reactive([]);
const acaoDeclaracoes = reactive([]);

onMounted(() => {
    //inicia os filtros de certificados e declarações
    carregarFiltros('Certificados');
    carregarFiltros('Declaracoes');
})


function carregarFiltros(sessao) {
    if (sessao === 'Certificados') {
        Object.values(certificados).forEach((certificado) => {
            let ano = certificado.dataEmissao.substring(0, 4);

            if (!acaoCertificados.includes(certificado.acao)) {
                acaoCertificados.push(certificado.acao);
            }
            if (!anoCertificados.includes(ano)) {
                anoCertificados.push(ano);
            }
        })
    }
    else if (sessao === 'Declaracoes') {
        Object.values(declaracoes).forEach((declaracao) => {
            let ano = declaracao.dataEmissao.substring(0, 4);

            if (!acaoDeclaracoes.includes(declaracao.acao)) {
                acaoDeclaracoes.push(declaracao.acao);
            }
            if (!anoDeclaracoes.includes(ano)) {
                anoDeclaracoes.push(ano);
            }
        })
    }
}

//Converte a data da data de emissão para o formato DD/MM/AAAA
function converterDatas(data) {
    data = new Date(data);

    const dia = String(data.getDate()).padStart(2, '0'); 
    const mes = String(data.getMonth() + 1).padStart(2, '0');
    const ano = data.getFullYear();

    return `${dia}/${mes}/${ano}`;
}

const certificadosFiltrados = computed(() => {
    //aplicando os filtros selecionados
    let filtro = Object.values(certificados).filter((certificado) => {
        return (certificado.acao.includes(filtroCertificados.value) ||
            certificado.dataEmissao.substring(0, 4).includes(filtroCertificados.value)
        )
    })

    //aplicando ordenação selecionada
    return Object.values(filtro).slice().sort((a, b) => {
        if (ordenacaoCertificados.value === 'Data(Mais recente primeiro)') {
            return new Date(b.dataEmissao) - new Date(a.dataEmissao);
        } else {
            return new Date(a.dataEmissao) - new Date(b.dataEmissao);
        }
    })
})

const declaracoesFiltradas = computed(() => {
    //aplicando os filtros selecionados
    let filtro = Object.values(declaracoes).filter((declaracao) => {
        return (declaracao.acao.includes(filtroDeclaracoes.value)) ||
            declaracao.dataEmissao.substring(0, 4).includes(filtroDeclaracoes.value)
    })

    //aplicando ordenação selecionada
    return Object.values(filtro).slice().sort((a, b) => {
        if (ordenacaoDeclaracoes.value === 'Data(Mais recente primeiro)') {
            return new Date(b.dataEmissao) - new Date(a.dataEmissao);
        } else {
            return new Date(a.dataEmissao) - new Date(b.dataEmissao);
        }
    })
})

const ordenacoes = [
    'Data(Mais recente primeiro)',
    'Data(Mais antigo primeiro)'
]

const certificados = reactive({
    certificado1: {
        titulo: 'Certificado 1',
        acao: 'Ação 1',
        dataEmissao: '2024-04-10'
    },
    certificado2: {
        titulo: 'Certificado 2',
        acao: 'Ação 2',
        dataEmissao: '2023-06-22'
    },
    certificado3: {
        titulo: 'Certificado 3',
        acao: 'Ação 1',
        dataEmissao: '2023-09-15'
    },
})

const declaracoes = reactive({
    declaracao1: {
        titulo: 'Declaracao 1',
        acao: 'Ação 1',
        dataEmissao: '2024-02-11'
    },
    declaracao2: {
        titulo: 'Declaracao 2',
        acao: 'Ação 7',
        dataEmissao: '2024-08-16'
    },
    declaracao3: {
        titulo: 'Declaracao 3',
        acao: 'Ação 5',
        dataEmissao: '2024-09-30'
    },
    declaracao4: {
        titulo: 'Declaracao 4',
        acao: 'Ação 1',
        dataEmissao: '2024-10-31'
    }

})
</script>
<style scoped>
.botao-download {
    font-size: 15px;
    font-weight: 500;
    text-align: center;
    text-decoration: none;
    width: min-content;
    background-color: transparent;
    color: white;
    border: none;
    height: 100%;
    border-radius: 1.95rem;
    display: block;
    padding: 2%;
}

.conteudo {
    background-color: rgb(0, 206, 0);
}

.conteudo button {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.botao-dropdown {
    border: none;
    border-bottom: 2px solid black;
    padding: 0;
    margin-bottom: 1%;
    background-color: transparent;
    width: 100%;
    text-align: start;
}

.ordenacao-e-filtros {
    display: flex;
    width: auto;
    border-bottom: 1px solid rgb(211, 211, 211);
    margin-bottom: 1%;
}

.filtros {
    width: 20%;
    margin-right: 2%;
    margin-bottom: 1%;
    border-right: 2px solid #6F319A;
}

.dropdown-menu button {
    width: 100%;
    background-color: transparent;
    border: none;
    border-bottom: 2px solid black;
}

.btn-group {
    margin: 0 5% 5% 0;
}

.btn {
    background-color: #6F319A;
}

.btn:hover{
    background-color: #451e61;
    transition: 500ms;
}

.btn.show{
    background-color: #6F319A;
}

.limpar-filtros {
    width: 70%;
    border: 2px solid #6F319A;
    color: #6F319A;
    border-radius: 15px;
}
</style>