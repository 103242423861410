<template>
    <div id="cadastro-noticia">
        <button @click.prevent="$emit('fechar-form-noticia')" id="botao-voltar">voltar</button>
        <form class="form-noticia">
            <h4>Cadastrar Notícia</h4>
            <div id="campos-form">
                <div class="mb-3">
                    <label for="exampleFormControlInput1" class="form-label">Título*</label>
                    <input type="text" class="form-control" v-model="noticia.titulo">
                </div>
                <div class="mb-3">
                    <label for="exampleFormControlTextarea1" class="form-label">Conteúdo(texto)*</label>
                    <textarea class="form-control" id="exampleFormControlTextarea1" rows="3"
                        v-model="noticia.conteudo"></textarea>
                </div>
                <div class="mb-3">
                    <label for="formFile" class="form-label">Imagem(Opcional)</label>
                    <input class="form-control" type="file" id="formFile" @change="coletarImagem($event)">
                </div>
                <div class="mb-3">
                    <label for="exampleFormControlInput1" class="form-label">Créditos da imagem(Opcional)</label>
                    <input type="text" class="form-control" v-model="noticia.creditosImagem"
                        :disabled="!imagemSelecionada">
                </div>
                <div class="mb-3">
                    <label for="exampleFormControlInput1" class="form-label">Palavras Chave*</label>
                    <div style="display: flex;">
                        <input type="text" class="form-control" v-model="palavra">
                        <button id="adicionar-palavra" @click.prevent="adicionarPalavra(palavra), palavra = ''"
                            >Adicionar palavra</button>
                    </div>
                    <span v-for="palavra in palavrasChave">{{ palavra + ', ' }}</span>
                </div>
                <div style="border-bottom: 2px dotted rgb(0, 0, 0, 0.2); margin-bottom: 2%; padding-bottom: 1%;">
                    Escopo*
                    <div>
                        <input class="form-check-input" type="radio" name="escopo" id="acao" value="acao"
                            v-model="noticia.escopoNoticia">
                        <label class="form-check-label" for="acao">
                            Ação
                        </label>
                    </div>
                    <div>
                        <input class="form-check-input" type="radio" name="escopo" id="unidade" value="unidade"
                            v-model="noticia.escopoNoticia">
                        <label class="form-check-label" for="unidade">
                            Unidade
                        </label>
                    </div>
                    <div>
                        <input class="form-check-input" type="radio" name="escopo" id="ufc" value="prex"
                            v-model="noticia.escopoNoticia">
                        <label class="form-check-label" for="ufc">
                            UFC(PREX)
                        </label>
                    </div>
                </div>
                <div style="margin-bottom: 5%;">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" value=true id="flexCheckDefault"
                            v-model="noticia.publicacaoProgramada">
                        <label class="form-check-label" for="flexCheckDefault">
                            Programar publicação?
                        </label>
                    </div>
                    <div v-if="noticia.publicacaoProgramada">
                        <p style="margin-bottom: 0;">Selecione a data:</p>
                        <input type="date" v-model="noticia.dataPublicacao" id="inputData">
                    </div>
                </div>
                <div class="mb-3">
                    <label for="exampleFormControlInput1" class="form-label">Fonte</label>
                    <input type="text" class="form-control" v-model="noticia.fonte">
                </div>
            </div>
            <div id="botoes">
                <button id="salvar" @click.prevent="">Salvar</button>
                <button id="enviar" @click.prevent="verificarNoticia">Enviar</button>
            </div>
        </form>
    </div>
</template>
<script setup>
import { Date } from 'core-js';
import { ref, reactive } from 'vue';

const emit = defineEmits(["fechar-form-noticia"]);

var palavra = ref('');
var imagemSelecionada = ref(false);

const palavrasChave = reactive([]);

function coletarImagem(event) {
    const imagem = event.target.files[0];

    if (imagem) {
        imagemSelecionada.value = true;

        const extensao = imagem.name.split(".").pop().toLowerCase();
        const extensoesPermitidas = ['png', 'jpg', 'jpeg'];

        noticia.imagem.nome = imagem.name.split(".")[0];

        if (!extensoesPermitidas.includes(extensao)) {
            alert('arquivo não permitido');
            return;
        }
        noticia.imagem.extensao = extensao;


        const leitor = new FileReader();
        leitor.readAsDataURL(imagem);

        leitor.onload = () => {
            noticia.imagem.base64 = leitor.result;
        }

        leitor.onerror = (error) => {
            console.error(error);
        }
    } else {
        imagemSelecionada.value = false;
    }

}

function adicionarPalavra(palavra) {
    if (palavra != '' && !palavrasChave.includes(palavra)) {
        palavrasChave.push(palavra);
    } else if (palavra == '') {
        alert('A palavra chave não pode ser vazia');
    } else {
        alert('Não é permitido palavras repetidas');
    }
}

const noticia = reactive({
    titulo: '',
    conteudo: ``,
    palavrasChave: palavrasChave,
    escopoNoticia: 'acao',
    autor: sessionStorage.getItem('acao-ativa'),
    dataCadastro: dataHoje(),
    dataPublicacao: '',
    publicacaoProgramada: false,
    fonte: '',
    imagem: {
        nome: '',
        extensao: '',
        base64: ``,
    },
    creditosImagem: ''
})

function dataHoje() {
    const hoje = new Date();
    const dia = hoje.getDate();
    const mes = hoje.getMonth() + 1;
    const ano = hoje.getFullYear();

    const dataFormatada = `${ano}-${mes.toString().padStart(2, '0')}-${dia.toString().padStart(2, '0')}`;

    return dataFormatada
}

function verificarNoticia() {
    let dataValida = false;

    if (noticia.publicacaoProgramada) {
        if (noticia.escopoNoticia === 'acao') {
            if (noticia.dataPublicacao != '') {
                if (noticia.dataPublicacao >= dataHoje()) {
                    dataValida = true;
                } else {
                    return alert('A data deve ser no futuro');
                }
            } else {
                return alert('Informe a data de publicação por favor');
            }
        } else {
            let data = new Date(dataHoje())
            if (noticia.dataPublicacao != '') {
                if (data.setDate(data.getDate() + 5) <= new Date(noticia.dataPublicacao)) {
                    dataValida = true;
                } else {
                    return alert('Para noticias publicadas fora da ação o tempo minímo para programação é de 5(Cinco) dias, contados a partir da data de hoje');
                }
            } else {
                return alert('Informe a data de publicação por favor');
            }
        }
    } else {
        dataValida = true;
        noticia.dataPublicacao = '';
    }

    if (noticia.titulo != '' && dataValida) {
        if (noticia.conteudo != '') {
            if (noticia.palavrasChave.length != 0) {
                console.log(noticia);
                alert('Notícia enviada com sucesso!');
                enviarNoticia();
                emit('fechar-form-noticia');
            } else {
                return alert('A notícia deve ter pelo menos uma palavra chave');
            }
        } else {
            return alert('O conteúdo da notícia não pode ser vazio');
        }
    } else {
        return alert('A notícia deve ter título');
    }
}

async function enviarNoticia() {
    //implementar envio da notícia
}

</script>
<style scoped>
#cadastro-noticia {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    background-color: rgb(0, 0, 0, 0.5);
    position: absolute;
    top: 0;
    left: 0;
}

.form-noticia {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 80%;
    margin: 0 30%;
    background-color: white;
    border-radius: 1.5rem;
    padding: 2%;
}

.form-noticia h4 {
    width: 75%;
    border-bottom: 2px solid black;
    padding-bottom: 1%;
    text-align: center;
}

#botao-voltar {
    margin-top: 5%;
    width: 15%;
    align-self: center;
    border: none;
    border-radius: 0.5rem 0.5rem 0 0;
    background-color: #EEE0F2;
}

#campos-form {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-top: 5%;
    width: 80%;
    padding: 0 2%;
    margin-bottom: 20px;
    overflow-y: scroll;
}

#campos-form input:focus {
    border: solid 2px #6F319A;
    box-shadow: none;
}

#campos-form textarea:focus {
    border: solid 2px #6F319A;
    box-shadow: none;
}

#campos-form input:checked {
    background-color: #6F319A;
    border-color: #6F319A;
}

#campos-form::-webkit-scrollbar {
    background-color: white;
    width: 10px;
}

#campos-form::-webkit-scrollbar-thumb {
    background-color: rgb(187, 187, 187);
    border-radius: 10px;
}

#botoes {
    display: flex;
    justify-content: space-between;
    width: 80%;
}

#botoes button {
    border: 2px solid #6F319A;
    border-radius: 10px;
    width: 25%;
}

#salvar {
    background-color: white;
    color: #6F319A;
}

#enviar {
    background-color: #6F319A;
    color: white;
}

#adicionar-palavra {
    border-radius: 8px;
    border-color: #6F319A;
    color: #6F319A;
}
</style>